.oh-planning-scheduling .fc {
  & .fc-resource-timeline-divider {
    width: 0px;
    cursor: none;
  }

  & .fc-timeline-slot {
    border-bottom: 0;
  }

  & .fc-scrollgrid {
    border-width: 0;
  }
  & td.fc-timeline-slot.fc-timeline-slot-lane.custom-year-lane {
    border-color: white;
    border-width: 0;
  }

  &
    td.fc-timeline-slot.fc-timeline-slot-lane.custom-year-lane[data-date$="01"] {
    border-color: var(--fc-border-color, #ddd);
    border-width: 1px;
  }

  & td.fc-timeline-slot.fc-timeline-slot-lane.custom-week-lane {
    border-color: white;
    border-width: 0;
  }

  & td.fc-timeline-slot.fc-timeline-slot-lane.custom-week-lane.fc-day-sun {
    border-width: 1px;
    border-color: var(--fc-border-color, #ddd);
  }

  & .fc-timeline-now-indicator-arrow.project-start {
    border-color: green transparent green transparent;
    border-width: 7px 6px 0 6px;
  }

  & .fc-timeline-now-indicator-line.project-start {
    border-color: green;
    border-width: 0 0 0 2px;
  }

  & .fc-timeline-now-indicator-arrow.project-end {
    border-color: orange transparent orange transparent;
    border-width: 7px 6px 0 6px;
  }

  & .fc-timeline-now-indicator-line.project-end {
    border-color: orange;
    border-width: 0 0 0 2px;
  }

  & .fc-timeline-lane.fc-resource.custom-row {
    border-width: 0;
    height: 5rem;
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
  }

  & .fc-resource .fc-datagrid-cell-frame {
    height: 5rem;
  }

  & .fc-timeline-event.fc-h-event.project-request {
    border: none;
    padding: 0;
    background-color: transparent;
  }

  & .fc-timeline-header .fc-timeline-header-row:nth-child(1) .fc-day-disabled {
    background-color: var(--chakra-colors-chakra-body-bg);
  }

  & .fc-timeline-header-row .fc-timeline-slot:last-child a {
    overflow: hidden !important;
    text-overflow: clip;
  }

  & .fc-license-message {
    display: none;
  }
}

html[data-theme="dark"] {
  .oh-planning-scheduling .fc {
    & .fc-day-disabled {
      background-color: var(--chakra-colors-gray-700);
    }

    & .custom-year-view {
      .fc-timeline-header
        .fc-timeline-header-row:nth-child(2)
        .fc-day-disabled {
        background-color: var(--chakra-colors-gray-800);
      }
    }

    & .custom-week-view {
      .fc-timeline-header
        .fc-timeline-header-row:nth-child(2)
        .fc-day-disabled {
        background-color: var(--chakra-colors-gray-800);
      }
    }
  }
}

html[data-theme="light"] {
  .oh-planning-scheduling .fc {
    & .fc-day-disabled {
      background-color: var(--chakra-colors-gray-100);
    }

    & .custom-year-view {
      .fc-timeline-header
        .fc-timeline-header-row:nth-child(2)
        .fc-day-disabled {
        background-color: var(--chakra-colors-chakra-body-bg);
      }
    }

    & .custom-week-view {
      .fc-timeline-header
        .fc-timeline-header-row:nth-child(2)
        .fc-day-disabled {
        background-color: var(--chakra-colors-chakra-body-bg);
      }
    }
  }
}
